









































  import { Component, Vue, Prop } from "vue-property-decorator";
 

  @Component({
    props:{
     	dataTableInfo: Object,
		itemTable: Array,
    },
    data() {
      return {
		isMobile: false,
      };
    },
    watch:{
    },
    async beforeMount() {
    },
    methods: {
      // tabla responsive
	onResize() {
		if (window.innerWidth < 961)
			this.$data.isMobile = true;
		else
			this.$data.isMobile = false;
	},
    },
    components: {
    },
  })
  export default class tableResumen extends Vue {
    @Prop() value!: number;
  }
