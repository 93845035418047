







































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  data() {
    return {
      canShowTooltip: false
    };
  },
  methods: {
    showTooltip() {
      if (this.$props.disabled) {
        this.$data.canShowTooltip = true;
      }
    },
    hideTooltip() {
      this.$data.canShowTooltip = false;
    },
    action() {
      if (this.$props.disabled) {
        return;
      }
      if (this.$props.to !== undefined) {
        this.$router.push({
          path: this.$props.to
        });
        this.$emit("click");
      } else {
        this.$emit("click");
      }
    }
  }
})
export default class TooltipDropdown extends Vue {
  @Prop() to!: string;
  @Prop() disabled!: boolean;
  @Prop() color!: string;
  @Prop() tooltipText!: string;
}
