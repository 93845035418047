



























































































































































































import { Component, Vue } from "vue-property-decorator";
import BusinessSelect from "@/components/dashboard/BusinessSelect.vue";
import ProjectStatus from "@/components/dashboard/ProjectStatusOn.vue";
import Resumen from "@/views/factoring/misOperaciones/tablaResumen.vue";
import {
  TitleTemplatePublic,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import API from "@/api";
	// @ts-ignore
import { Tabs, TabItem } from 'vue-material-tabs';
import { mapState } from "vuex";
import { EventBus } from "@/main";
// @ts-ignore
import TableResponsive from "@/components/table/TableResponsive.vue";
import ShowLoading from "@/components/panel/ShowLoading.vue";


@Component({
  metaInfo: {
    title: "Mis operaciones",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
			return this.$store.state.openMenuPanel;
		},
    filtersActives() {
      //@ts-ignore
      return (this.filter_deudor !== null  && this.filter_deudor !== undefined) || (this.filter_moneda !== null && this.filter_moneda !== undefined) || (this.filter_estado !== null && this.filter_estado !== undefined);
    },
  },
  data() {
    return {
      cargardatos: false,
      tableResumen: {
				headers: [
					{
						text: "",
						align: "center",
						sortable: false,
					},
					{
						text: "Pagadas",
						align: "center",
						sortable: false,
					},
					{
						text: "Vigentes",
						align: "center",
						sortable: false,
					},
					{
						text: "Morosas",
						align: "center",
						sortable: false,
					}
				],
				msjEmpty: 'No existen registros',
				rowsPerPage: {
					txt: 'Registro por página',
					itm: [10, 25, 50, { text: 'Todos', value: -1 }]
				},
			},
      dataResume: {
        soles:[],
        dolares: []
      },
      resumen_soles: [],
      active: 0,
      tableProductos:{
        headers: [
					{
						text: "Deudor",
						align: "center",
						sortable: false,
					},
					{
						text: "Serie",
						align: "center",
						sortable: false,
					},
					{
						text: "Folio",
						align: "center",
						sortable: false,
					},
					{
						text: "Fecha emisión",
						align: "center",
						sortable: false,
					},
					{
						text: "Fecha vencimiento",
						align: "center",
						sortable: false,
					},
					{
						text: "Monto",
						align: "center",
						sortable: false,
					},
					{
						text: "Suma abono",
						align: "center",
						sortable: false,
					},
					{
						text: "Saldo",
						align: "center",
						sortable: false,
					},
					{
						text: "Días mora",
						align: "center",
						sortable: false,
					},
					{
						text: "Moneda",
						align: "center",
						sortable: false,
					},
					{
						text: "Estado",
						align: "center",
						sortable: false,
					},
				],
				msjEmpty: 'No existen registros',
				rowsPerPage: {
					txt: 'Registro por página',
					itm: [10, 25, 50, { text: 'Todos', value: -1 }]
				},
      },
      filter_deudor: null,
      filter_moneda:null,
      filter_estado:null,
      filters:{
        deudor:[],
        moneda:[],
        estados: []
      },
      data: {
        factoring:[],
        confirming:[],
        pronto_pago: []
      },
      dataFactoring: [],
      dataPronto: [],
      dataConfirming: [],
      empresasSincronizadas: [],
      companySelected: null,
      
    };
  },
  
  watch: {
    'active':{
      handler(val) {
          switch(val){
            case 0:
              //@ts-ignore
              this.$data.filters.deudor = this.buildFilterDeudores(this.$data.dataConfirming);
              break;
            case 1:
              //deudores de pronto
              //@ts-ignore
              this.$data.filters.deudor = this.buildFilterDeudores(this.$data.dataPronto);
              break;
            case 2:
              //deudores de factoring
              //@ts-ignore
              this.$data.filters.deudor = this.buildFilterDeudores(this.$data.dataFactoring);
              break;
          }
      },
    },
    'companySelected': {
      handler(val){
        //@ts-ignore
        this.loadData(val);
      }
    }
  },
  async beforeMount() {
    //@ts-ignore
    this.dataInicial();
   
  },
  methods:{
    setNombre(nombre:string){
      switch(nombre){
        case 'factoring':
          return 'Factoring'
        case 'pronto_pago':
          return 'Pronto Pago';
        case 'confirming':
          return 'Confirming'
        case 'total':
          return 'Total'

      }
    },
    formatTable(array:string[]){
      const data = Object.entries(array);
			/* Formateando Data */
			let propsObjTableAll = {
				style: `text-align: center;
						font-size: 0.7rem;
						font-weight: 500;
						color: #8b8b8b`
			}
			Array.from(data, column => {
				let objTable = {
					producto:{
						// @ts-ignore
						value: this.setNombre(column[0]),
						label: '',
						props: propsObjTableAll
					},
					pagadas:{
						// @ts-ignore
						value: column[1].pagadas != null ? column[1].pagadas : 0,
						label: 'Pagadas',
						props: propsObjTableAll
					},
					vigentes:{
						// @ts-ignore
						value: column[1].vigentes != null ? column[1].vigentes : 0,
						label: 'Vigentes',
						props: propsObjTableAll
					},
					morosas:{
						// @ts-ignore
						value: column[1].morosas != null ? column[1].morosas : 0,
						label: 'Morosas',
						props: propsObjTableAll
					},
				}
				// @ts-ignore
        column.datosEnTabla = objTable;
				// @ts-ignore
			});

			return data;
		},
    formatTableProducto(array:string[]){
			/* Formateando Data */
			let propsObjTableAll = {
				style: `text-align: center;
						font-size: 0.7rem;
						font-weight: 500;
						color: #8b8b8b`
			}
			Array.from(array, column => {
				let objTable = {
					deudor:{
						// @ts-ignore
						value: column.deudor.nombre,
						label: 'Deudor',
						props: propsObjTableAll
					},
					serie:{
						// @ts-ignore
						value: column.serie != null ? column.serie : '-',
						label: 'Serie',
						props: propsObjTableAll
					},
					folio:{
						// @ts-ignore
						value: column.folio,
						label: 'Folio',
						props: propsObjTableAll
					},
					fecha_emision:{
						// @ts-ignore
						value: column.fecha_emision,
						label: 'Fecha emisión',
						props: propsObjTableAll
					},
					fecha_vencimiento:{
						// @ts-ignore
						value: column.fecha_vencimiento,
						label: 'Fecha vencimiento',
						props: propsObjTableAll
					},
					monto:{
						// @ts-ignore
						value: column.monto,
						label: 'Monto',
						props: propsObjTableAll
					},
					suma_bono:{
						// @ts-ignore
						value: column.suma_abono,
						label: 'Suma bono',
						props: propsObjTableAll
					},
					saldo:{
						// @ts-ignore
						value: column.saldo,
						label: 'Saldo',
						props: propsObjTableAll
					},
					dias_mora:{
						// @ts-ignore
						value: column.mora_dias,
						label: 'Días mora',
						props: propsObjTableAll
					},
					moneda:{
						// @ts-ignore
						value: column.tipo_moneda.nombre,
						label: 'Moneda',
						props: propsObjTableAll
					},
					estado_onboarding:{
						// @ts-ignore
						value: column.onboarding_estado.description,
						label: 'Estado',
						props: propsObjTableAll
					},
				}
				// @ts-ignore
        column.datosEnTabla = objTable;
				// @ts-ignore
			});

			return array;
    },
    buildFilterDeudores(dataArray) {
      const deudores = [];

      for (const item of dataArray) {
          deudores.push({
            id: item.deudor.id,
            nombre: item.deudor.nombre,
          });
      }
      return deudores;
    },
    async dataInicial(){

      this.$data.cargardatos = true;

      const [empresasResponse, estadosResponse, monedasResponse] = await Promise.all([
        API.getEmpresasSUNAT(),
        API.getEstadosOnboarding(),
        API.getMonedas()
      ]);

      if(empresasResponse.status === 200){
        this.$data.empresasSincronizadas = empresasResponse.data.data;
        this.$data.companySelected = this.$data.empresasSincronizadas[0].id;
      }

      if(estadosResponse.status === 200){
        this.$data.filters.estados = estadosResponse.data.data;
      }
      
      this.$data.filters.moneda = monedasResponse;

    },
    async loadData(empresa_id:number) {

      this.$data.cargardatos = true;
      
      const [resumeResponse, operacionesResponse] = await Promise.all([
        API.getOperacionesResumeEmpresa(empresa_id),
        API.getOperacionesEmpresa(empresa_id),
      ]);
      
      const resumeData = resumeResponse.data;

      if(resumeData.status == 200){
        let soles = resumeData.data.soles; 
        let dolares = resumeData.data.dolares;

        if (dolares.length !== 0) {
          //@ts-ignore
          this.$data.dataResume.dolares = this.formatTable(dolares);
        }
        
        if (soles.length !== 0) {
          //@ts-ignore
          this.$data.dataResume.soles = this.formatTable(soles);
        }
      }
      
      const operacionesData = operacionesResponse.data;
      if (operacionesData.status === 200) {
        this.$data.dataFactoring = operacionesData.data.factoring;
        this.$data.dataPronto = operacionesData.data.pronto_pago;
        this.$data.dataConfirming = operacionesData.data.confirming;
        
        // @ts-ignore
        this.$data.data.factoring = this.formatTableProducto(this.$data.dataFactoring);
        // @ts-ignore
        this.$data.data.confirming = this.formatTableProducto(this.$data.dataConfirming);
        // @ts-ignore
        this.$data.data.pronto_pago = this.formatTableProducto(this.$data.dataPronto);

        //@ts-ignore
        this.$data.filters.deudor = this.buildFilterDeudores(this.$data.dataConfirming);
        this.$data.cargardatos = false;
        this.$data.active = 0;
        //@ts-ignore
        this.limpiarFiltros();
      }
      
      else{
        this.$data.active = 0;
        this.$data.cargardatos = false;
        //@ts-ignore
        this.limpiarFiltros();
      }

    },
    filtrarDatos(datos, filterDeudor, filterMoneda, filterEstado) {
      let datosFiltrados : any = [... datos]; 

      if (filterDeudor !== null && filterDeudor !== undefined) {
        datosFiltrados = datosFiltrados.filter((item : any) => {
          return item.deudor.id === filterDeudor;
        });
      }
      
      if (filterMoneda !== null && filterMoneda !== undefined ) {
        datosFiltrados = datosFiltrados.filter((item : any) => {
          return item.moneda_id === filterMoneda;
        });
      }
      
      if (filterEstado !== null && filterEstado !== undefined) {
        datosFiltrados = datosFiltrados.filter((item : any) => {
          return item.onboarding_estado.id === filterEstado;
        });
      }
      return datosFiltrados;
    }, 
    changeSelector(){
      switch(this.$data.active){
        case 0:
          //@ts-ignore
          return this.filtrarDatos(this.data.confirming, this.$data.filter_deudor, this.$data.filter_moneda, this.$data.filter_estado);
        case 1: 
          //@ts-ignore
          return this.filtrarDatos(this.data.pronto_pago, this.$data.filter_deudor, this.$data.filter_moneda, this.$data.filter_estado);
        case 2:
          //@ts-ignore
          return this.filtrarDatos(this.data.factoring, this.$data.filter_deudor, this.$data.filter_moneda, this.$data.filter_estado);
      }
    }, 
    limpiarFiltros(){
      this.$data.filter_deudor = null;
      this.$data.filter_moneda= null;
      this.$data.filter_estado = null;
    }

  },
  components: {
    BusinessSelect,
    ProjectStatus,
    Resumen,
    Tabs,
		TabItem,
    TableResponsive,
    ShowLoading
  },
})
export default class Myoperations extends Vue {}
