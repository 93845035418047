


































































































import { Component, Vue } from "vue-property-decorator";
import {
	RedCapital,
	SelectedCountry,
	Countries,
	TitleTemplateDashboard,
} from "@/configuration";
import API from "@/api";
import { mapState } from "vuex";
import TooltipButton from "@/components/TooltipButton.vue";
import TooltipDropdown from "@/components/TooltipDropdown.vue";
import TableResponsive from "@/components/table/TableResponsive.vue";
import ShowLoading from "@/components/panel/ShowLoading.vue";
import { EventBus } from "@/main";

@Component({
	computed: {
		...mapState(["user", "business", "setOpenMenuPanel"]),
		setOpenMenuPanel(){
			return this.$store.state.openMenuPanel;
		},
	},
	metaInfo: {
		title: "Mis empresas",
		titleTemplate: TitleTemplateDashboard,
		htmlAttrs: {
			// @ts-ignore
			lang: RedCapital[SelectedCountry].Lang,
		},
	},
	data() {
		return {
			canShow: false,
			searchEmpresas: "",
			paso: null,
			idmepresa: [],
			dialog: false,
			RedCapital,
			SelectedCountry,
			search: "",
			showTooltip: false,
			businessData: [],
			clonBusinessData: null,
			showMore: false,
			showId: null,
			regions: null,
			communes: null,
			tablaEmpresas: {
				headers: [
					{
						// @ts-ignore
						text: `RUC empresa`,
						align: "center",
						value: "ruc",
					},
					{
						text: "Nombre",
						align: "center",
						value: "nombre",
					},
					{
						text: "Seleccionar",
						align: "center",
						sortable: false,
					},
					{
						text: "Editar",
						align: "center",
						sortable: false,
					}
				],
				msjEmpty: 'No existen registros en tu cuenta',
				rowsPerPage: {
					txt: 'Registro por página',
					itm: [10, 25, 50, { text: 'Todos', value: -1 }]
				},
			},
			headers: [
				/*{
					text: "Id empresa",
					align: "center",
					value: "id"
				},*/
				/*{
					text: 'Nombre',
					value: 'nombre',
				},*/
				{
					// @ts-ignore
					text: `RUC empresa`,
					align: "center",
					value: "ruc",
				},
				{
					// @ts-ignore
					text: `Nombre`,
					align: "center",
					value: "nombre",
				},
				/*{
					text: "Telefono",
					value: "telefono"
				},*/
				{
					text: "Seleccionar",
					align: "center",
					sortable: false,
				},
				{
					text: "Editar",
					align: "center",
					sortable: false,
				},
				// {
				//   text: "Factura",
				//   align: "center",
				//   sortable: false,
				// },
			],
			staticCountries: Countries,
			countries: null,
		};
	},
	async beforeMount() { // TODO: Cuando tenga tiempo cambiar forma de cargar el dataTable para darle la interaccion al btn seleccionar
		// @ts-ignore
		this.$data.businessData = await API.getBusiness();
		this.$data.countries = await API.getCountries();
		
		/* Formateando Data */
		let propsObjTableAll = {
			style: `text-align: center;
					font-size: 0.7rem;
					font-weight: 500;
					color: #8b8b8b`
		}
		let propsObjTableButtons = {
			style: `display: flex;
					justify-content: center;`
		}
		Array.from(this.$data.businessData, business =>{
			// @ts-ignore
			business.datosEnTabla;
			// @ts-ignore
			/* Seleccionar Empresa */
			let chipAttr = {};
			if(this.$store.state.businesSelected != null){
				// @ts-ignore
				if(this.$store.state.businesSelected.id == business.id){
					// @ts-ignore
					chipAttr.text = 'Seleccionada';
					// @ts-ignore
					chipAttr.selected = true;
					// @ts-ignore
					chipAttr.class = 'rc-chip__selected';
				}else{
					// @ts-ignore
					chipAttr.funcion = 'solicitanteMisEmpresas_solcilitarChild';
					// @ts-ignore
					chipAttr.text = 'Seleccionar';
					// @ts-ignore
					chipAttr.class = 'rc-chip';
					// @ts-ignore
					chipAttr.data = business;
					// @ts-ignore
					chipAttr.disabled = business.validado == 0 ? false : true;
				}
			}else{
				// @ts-ignore
				chipAttr.funcion = 'solicitanteMisEmpresas_solcilitarChild';
				// @ts-ignore
				chipAttr.text = 'Seleccionar';
				// @ts-ignore
				chipAttr.class = 'rc-chip';
				// @ts-ignore
				chipAttr.data = business;
				// @ts-ignore
				chipAttr.disabled = business.validado == 0 ? true : false;
			}

			let objTable = {
				rutEmpresa:{
					// @ts-ignore
					value: business.rut,
					label: 'RUC Empresa',
					props: propsObjTableAll
				},
				nombreEmpresa:{
					// @ts-ignore
					value: business.nombre,
					label: 'Nombre Empresa',
					props: propsObjTableAll
				},
				seleccionar:{
					// @ts-ignore
					value: '', /* TODO: pendiente de agregar un boton para seleccionar las empresas requeridas */
					label: 'Seleccionar',
					props: propsObjTableAll,
					chip: chipAttr

				},
				acciones:{
					// @ts-ignore
					value: '',
					label: 'Acciones',
					props: propsObjTableButtons,
					button: {
						disabled: true,
						funcion: 'solicitanteMisEmpresas_editChild',
						icoFont: 'create',
						notOutline: true,
						// @ts-ignore
						data: business.id
					},
				},
			}
			//@ts-ignore
			business.datosEnTabla = objTable
		})

		this.$data.canShow = true;
	},
	methods: {
		solcilitar(business){
			this.$data.dialog = true;
			/* DELL */
			delete business.datosEnTabla;
			this.$store.commit("setBusinessSelected", business);
			setTimeout(() => {
				this.$data.dialog = false;
				this.$router.push("/panel-facto/estadisticas");
			},500)
		},
		selected(business) {
			this.$data.dialog = true;
			this.$store.commit("setBusinessSelected", business);
			setTimeout(() => {
				this.$data.dialog = false;
				this.$router.push("/panel-facto/anticipo-facturas");
			}, 1000);
		},
		edit(id: number) {
			this.$router.push({
				path: `/panel-facto/editar-empresa/${id}`,
			});
		},
		request(id: number) {
			this.$store.commit("setBusiness", id);
		},
		getBusinessById(id: number) {
			return this.$data.businessData.find((el: any) => el.empresa.id == id);
		},
		getRegionText(regionId: number) {
			return regionId == null
				? "Desconocido"
				: this.$data.regions.find((el: any) => el.id == regionId);
		},
		getCommuneText(communeId: number) {
			return communeId == null
				? "Desconocido"
				: this.$data.communes.find((el: any) => el.id == communeId);
		},
		goToAction() {
			this.$store.commit("deleteSimulation");
			this.$router.push({
				path: "/conocerte",
			});
		},
	},
	components: {
		TooltipButton,
		TooltipDropdown,
		TableResponsive,
		ShowLoading
	},
})
export default class Business extends Vue {}
