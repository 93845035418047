


















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  data() {
    return {
      estados: [
        {
          id: 1,
          text: "Solicitado",
          estadoSolicitud:"tu solicitud ha sido ingresada y será revisada por nuestras ejecutivas."
        },
        {
          id: 2,
          text: "Rechazado",
          estadoSolicitud:"tu solicitud no cumple con nuestros requisitos y por el momento no ha sido aprobada."
        },
        {
          id: 3,
          text: "Pre-aprobado",
          estadoSolicitud:"tu solicitud ha sido pre-aprobada"
        },
        {
          id: 4,
          text: "Aprobado",
          estadoSolicitud:"¡felicitaciones! Tu solicitud ha pasado nuestros filtros y ya se encuentra aprobada."
        },
        {
          id: 5,
          text: "Activo",
          estadoSolicitud:"Tu solicitud"
        },
        {
          id: 6,
          text: "Finalizado",
          estadoSolicitud:"tu solicitud ha finalizado. Si lo deseas, puedes hacer una nueva solicitud."
        },
        {
          id:7,
          text:"En proceso",
          estadoSolicitud:"tu solicitud ha sido creada y pronto será procesada y revisada por nuestras ejecutivas."
        },
        {
          id:8,
          text:"Cambio tipo solicitud",
          estadoSolicitud:"tu tipo de solicitud ha sido cambiada por otro tipo que se ajusta mejor a tus necesidades."
        },
        {
          id:9,
          text:"Reservado para proyecto",
          estadoSolicitud:"hemos reservado tu cupo para el proyecto seleccionado."
        },
        {
          id:10,
          text:"Proyecto Creado",
          estadoSolicitud:"tu solicitud ha sido creada con éxito."
        },
        {
          id:11,
          text:"Por Completar Scoring",
          estadoSolicitud:"tu solicitud está incompleta, por favor rellena los datos que faltan."
        },
        {
          id:12,
          text:"Solicitud Renovacion",
          estadoSolicitud:"tu solicitud para renovación está siendo procesada."
        },
        {
          id:13,
          text:"En Validación",
          estadoSolicitud:"tu solicitud está siendo procesada y revisada por nuestras ejecutivas."
        },
        {
          id:14,
          text:"Aprobado por cliente",
          estadoSolicitud:"Aprobado por cliente."
        },
        {
          id:15,
          text:"Interno RedCapital",
          estadoSolicitud:"Interno RedCapital."
        },
        {
          id:16,
          text:"Retracto",
          estadoSolicitud:"Retracto (Por decisión de Cliente)."
        },
        {
          id:17,
          text:"Retracto",
          estadoSolicitud:"Desistimiento (Por no respuesta de cliente cliente)"
        }
      ]
    };
  },
  methods: {
    estadoSolicitud(id: number) {
      return this.$data.estados.find((estadoSolicitud: any) => estadoSolicitud.text == id).estadoSolicitud;
    }
  }
})
export default class ProjectStatus extends Vue {
  @Prop() projectStatus!: any;
}
